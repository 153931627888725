<template>
    <v-card-actions class="d-block">
        <v-divider></v-divider>
        <div class="d-flex flex-row-reverse mt-2">
            <slot></slot>
            <v-spacer></v-spacer>
            <slot name="secondary"></slot>
        </div>
    </v-card-actions>
</template>

<script>
    export default {
        name: 'card-actions',
    };
</script>